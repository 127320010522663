@import '../../app/styles/themify.module.scss';
@import '../../app/styles/vars.module.scss';

.opposite-logo {
  font-family: ArialBlack;
  font-size: var('text--md');
  text-transform: uppercase;
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  float: right;
  height: 35px;
  cursor: pointer;
  @include themify() {
    background: theme('shop-switcher__background');
    color: theme('shop-switcher__text') !important;
  }
}

.opposite-logo--go-to-shop {
  font-size: var('text--sm') !important;
  font-family: inherit;
}

.opposite-logo__link {
  @include themify() {
    color: theme('shop-switcher__text') !important;
  }
}
